.button {
  padding: 16px;
  font-size: 20px;
  line-height: 1;
  background-color: transparent;
  border: 0;
  border: 1px solid #0001;
  outline: none;
  color: #ffce5c;
  transition: all 300ms;
  &:hover {
    color: #00a3f5;
    border: 1px solid #ffce5c22;
  }
  &--long-text {
    font-size: 14px;
  }
}
