.calculator {
  position: relative;
  width: 320px;
  margin: 0 auto;
  background-color: #292f36;
  box-shadow: 0 19px 38px rgba(19, 19, 19, 0.24), 0 15px 12px rgba(19, 19, 19, 0.12);
  &__result {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 320px;
    height: 80px;
    padding: 8px;
    font-size: 32px;
    line-height: 2;
    background-color: rgba(19, 19, 19, 0.64);
    color: #60d63c;
    text-align: right;
    white-space: nowrap;
  }
  &__numbers {
    float: left;
    width: 240px;

    button {
      position: relative;
      display: block;
      float: left;
      width: 80px;
      height: 80px;
      &:last-child {
        width: 100%;
      }
    }
  }
  &__functions {
    float: left;
    width: 240px;
    button {
      display: inline-block;
      width: 80px;
      height: 80px;
      color: #d63c6b;
      &:hover {
        color: #b696a0;
      }
    }
  }
  &__math-operations {
    button {
      display: inline-block;
      width: 80px;
      height: 80px;
      color: #d63c6b;
      &:hover {
        color: #b696a0;
      }
    }
  }
}
